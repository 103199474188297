.p-Finder {
  @include header-spacer("padding-top");
  overflow: hidden;
}

.p-Finder-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

.p-Finder-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid $site-header-border-color;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.p-Finder-toggle {
  @include text-small();
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.p-Finder-total {
  @include text-small();
  font-weight: 500;
  text-transform: uppercase;
}

.p-Finder-panelHeader {
  @include text-small();
  text-transform: uppercase;
  font-weight: 500;
  a {
    margin-left: 1em;
    opacity: 0.6;
    display: none;
    font-weight: 400;
    text-transform: none;
  }
  span:not([data-filter-total=""]):not([data-filter-total="0"]) {
    &:after {
      padding-left: 0.5em;
      content: '(' attr(data-filter-total) ')';
    }
    + a {
      display: inline-block;
    }
  }
}

.p-Finder-panelSectionHeader {
  font-size: calc(1rem - 2px);
  text-transform: none;
  font-weight: 700;
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}

.p-Finder-panelAccordion {
  border-top: 1px solid #ddd;
  &:last-child {
    border-bottom: 1px solid #ddd;
  }
}

.p-Finder-panel-subTotal {
  &:not([data-filter-subtotal=""]):not([data-filter-subtotal="0"]) {
    &:before {
      padding-left: 0.5em;
      content: '(' attr(data-filter-subtotal) ')';
    }
  }
}

.p-Finder-panelAccordion-toggle {
  @include text-small();
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.p-Finder-panelAccordion-toggleIcon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  position: relative;
  transition: transform 0.6s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.2s;
  }
  &:before {
    background: url(svg("plus", "fill: none; stroke: #000; stroke-width: 1px")) center/contain no-repeat;
  }
  &:after {
    opacity: 0;
    background: url(svg("minus", "fill: none; stroke: #000; stroke-width: 1px")) center/contain no-repeat;
  }
  .p-Finder-panelAccordion-toggle.is-open & {
    transform: rotate(180deg);
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.p-Finder-panelAccordion-content {
  @include text-small();
  overflow: hidden;
  margin: 0 -1rem;
  padding: 0 1rem;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 0 1rem 0;
  }
  li {
    padding: 0.3em 0;
  }
  .Finishing {
    margin-right: 0.75em;
  }
  a {
    text-decoration: none !important;
    &.is-disabled {
      pointer-events: none;
    }
  }
  a.is-disabled .p-Finder-panelLabel {
    opacity: 0.3;
  }
  ul:not(.p-Finder-finishingFilters) {
    a {
      display: flex;
      align-items: center;
      position: relative;
      &:before {
        content: ' ';
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.75rem;
        display: inline-block;
        border: 1px solid #ccc;
      }
    }
    a.is-active:before {
      background: url(svg("flag", "fill: none; stroke: #000; stroke-width: 1.2px")) center/contain no-repeat;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      margin-left: -0.25rem;
      border: 0;
    }
  }
}

.p-Finder-panelForm {
  position: relative;
}

.p-Finder-panelForm-input {
  @include text-small();
  appearance: none;
  border: 0;
  background-color: $color-background-gray;
  width: 100%;
  padding: 0.75rem;
}

.p-Finder-panelForm-button {
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.75;
}
