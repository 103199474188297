.Manifest {
  --vw100: calc(var(--actual-vh) * 100);
  --color-text: #fff;

  background: #000;
  color: #fff;
  position: relative;
  overflow: hidden;

  strong,
  strong * {
    font-weight: 500;
  }

  html.is-mobile & .HpSlider-hint {
    display: block;
    bottom: 25vh;
  }

  [data-manifest-scroll-hint] {
    opacity: 0;
    transition: opacity 1s;

    html.has-scrolled & {
      opacity: 0 !important;
    }
  }

  &.is-ready [data-manifest-scroll-hint] {
    opacity: 1;
  }

  [data-manifest-split] {
    .line {
      transform: translateX(calc(20% * var(--sign, 1)));
      opacity: 0;
      transition: transform 1.6s cubic-bezier("easeOutCubic"), opacity 1.6s;
      will-change: opacity;

      &:nth-child(even) {
        --sign: -1;
      }
    }
  }

  [data-manifest-split].is-inview .line,
  .is-inview [data-manifest-split] .line {
    opacity: 1;
    transform: none;
  }
}

.Manifest-stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.Manifest-preload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;

  svg {
    position: absolute;
    width: 100px;
    height: 100px;
    animation: fancybox-rotate 1s linear infinite;
  }

  circle {
    stroke: #fff;
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.Manifest-percent {
  font-size: calc(1rem - 4px);
  font-weight: 700;
  display: block;
}

.Manifest-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
}

.Manifest-main {
  position: relative;
  z-index: 2;
  padding: 0 0 1px;
  min-height: 100vh;
}

.Manifest-intro {
  height: 160vh;

  h1 {
    @include ms-respond(font-size, 48);
    height: 100vh;
    display: flex;
    font-weight: 400;
    max-width: 13em;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    span {
      --intro-x: calc(20vw - var(--intro) * 20vw);
      --scroll-x: calc(var(--scroll) * -100vw);
      --x: calc((var(--intro-x) + var(--scroll-x)) * var(--sign, 1));

      --opacity-intro: calc(0 + var(--intro, 0));
      --opacity-scroll: calc(var(--scroll, 0) * -2);
      --opacity: calc(var(--opacity-intro) + var(--opacity-scroll));
      text-align: center;

      display: block;
      transform: translateX(var(--x, 0));
      opacity: var(--opacity);
      will-change: transform;

      &:last-child() {
        --sign: -1;
        // text-align: right;
      }
    }
  }
}

.Manifest-highlight {
  // @include section-spacer("margin-bottom", 1);
  // background: rgba(0, 0, 0, 0.1);
}

.Manifest-highlight-headline * {
  @include ms-respond(font-size, 40);
  font-weight: 400;
  text-align: center;
  max-width: 22ch;
  margin: auto;

  @include media-breakpoint-down(xs) {
    font-size: 23px;
  }
}

.Manifest-highlight-prose {
  @include reveal();
  @include section-spacer("margin-top", 0.75);

  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include make-col-offset(6);
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);
    @include make-col-offset(7);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
  }
}

.Manifest-roads {
  @include section-spacer("margin-bottom", 1);
}

.Manifest-roads-headline {
  @include section-spacer("margin-bottom", 1);
  align-self: flex-start;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    margin-bottom: 0 !important;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5);
    @include make-col-offset(1);
  }
}

.Manifest-roads-headline * {
  @include ms-respond(font-size, 40);
  font-weight: 400;
  margin: 0;

  @include media-breakpoint-down(xs) {
    font-size: 23px;
  }

  strong {
    font-weight: 500;
  }
}

.Manifest-roads-content {
  @include media-breakpoint-up(sm) {
    @include section-spacer("padding-top", 2);
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    @include make-col(5);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
}

.Manifest-road {
  @include reveal();
  @include section-spacer("margin-bottom", 1);

  &:last-child {
    margin-bottom: 0 !important;
  }

  h6 {
    font-size: calc(1rem - 2px);
    margin: 0 0 0.75em;
  }

  p {
    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.6;
    }
  }
}

.Manifest-ctas {
  .row {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}

.Manifest-cta {
  @include reveal();
  @include section-spacer("margin-bottom", 1);
  @include make-col-auto();
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include make-col-offset(2);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(5);
    // @include make-col-offset(2);
  }

  &:nth-child(even) {
    @include media-breakpoint-up(sm) {
      @include make-col-offset(5);
    }
  }

  h2 {
    @include ms-respond(font-size, 40);
    font-weight: 400;
    margin: 0 0 0.5em;
    max-width: 10em;

    @include media-breakpoint-down(xs) {
      font-size: 23px;
    }
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
