.NewsSlider {
  @include section-spacer("margin-bottom");
  overflow: hidden;
  // padding-bottom: 1px; // fix swiper-scrollbar flicker
  .swiper-container {
    @include reveal();
    @include res-prop(("margin-left", "margin-right"), $grid-gutter-widths, -0.5);
    overflow: visible;
    padding-bottom: 4rem;
  }
  .swiper-slide {
    @include res-prop(("padding-left", "padding-right"), $grid-gutter-widths, 0.5);
    @include res-prop("width", (xs: 90%, sm: 50%, md: 33.33%));
  }
  .swiper-scrollbar {
    @include res-prop(("left", "right"), $grid-gutter-widths, 0.5);
  }
}

.NewsSlider-col {
  margin: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(10);
  }

}

.NewsList-title {
  @include reveal("[data-scroll]");
  margin-bottom: calc(4rem - 1em);
}
