.Link {
  display: inline-block;
  text-decoration: none !important;
  @include text-xsmall();
  font-weight: 500;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;
  position: relative;
  &:after {
    flex-shrink: 0;
    content: '';
    vertical-align: middle;
    display: inline-block;
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-image: url(svg-encode('<svg xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32"><path style="fill:none;stroke:#000000;stroke-width:#{$icon-stroke-width}" d="M49.1,11.1L54,16l-4.9,4.9 M42,16h12"/><path style="fill:none;stroke:#FFFFFF;stroke-width:#{$icon-stroke-width}" d="M17.1,11.1L22,16l-4.9,4.9 M10,16h12"/></svg>'));
    background-size: 4rem 2rem;
    background-repeat: no-repeat;
    background-position: -2rem 0;
    box-shadow: inset 0 0 0 1.2px currentColor;
    transform: translateZ(0);
    transition: background 0.3s;
    background-color: transparent;
    .color-inverted & {
      background-image: url(svg-encode('<svg xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32"><path style="fill:none;stroke:#fff;stroke-width:#{$icon-stroke-width}" d="M49.1,11.1L54,16l-4.9,4.9 M42,16h12"/><path style="fill:none;stroke:#000;stroke-width:#{$icon-stroke-width}" d="M17.1,11.1L22,16l-4.9,4.9 M10,16h12"/></svg>'));
    }
  }
  &:hover:after {
    background-color: currentColor;
    background-position: 0 0;
  }
}
