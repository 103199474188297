.OffCanvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-off-canvas;
  pointer-events: none;
  overflow: hidden;
  .show-off-canvas & {
    pointer-events: auto;
  }
}

.OffCanvas.--inverted {
  color: $color-text-inverted;
}

.OffCanvas-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.6s;
  .show-off-canvas & {
    opacity: 0.2;
  }
}

.OffCanvas-panel {
  position: absolute !important; // overwrite BlockUI's relative position
  top: 0;
  right: 0;
  height: 100%;
  width: 85%;
  max-width: rem(600px);
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.4s;
  .show-off-canvas & {
    transform: translateX(0);
  }
  .OffCanvas.--inverted & {
    background-color: $color-background-black;
  }
}

.OffCanvas-header {
  @include res-prop("height", $site-header-heights);
  @include res-prop("padding-left", $grid-container-paddings);
  @include res-prop("padding-right", $grid-container-paddings, 0.5);
  @include res-prop("right", $grid-container-paddings, 0.5);
  left: 0;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  background-color: #fff;
  .OffCanvas.--inverted & {
    background-color: $color-background-black;
  }
}

.OffCanvas-close {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  flex-shrink: 0;
  margin-left: auto;
  color: inherit;
  .Icon {
    $scale: 1.5;
    width: 1rem * $scale;
    height: 1rem * $scale;
    stroke-width: $icon-stroke-width * 1 / $scale;
  }
}

.OffCanvas-content {
  @include res-prop(("padding-left", "padding-right"), $grid-container-paddings);
  @include res-prop("padding-top", $site-header-heights, 2);
  @include res-prop("padding-bottom", $site-header-heights, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
