.p-ItemList {
  @include section-spacer(("margin-top","margin-bottom"));
  .p-FinishingItemList & {
    margin-bottom: 0;
    margin-top: 5rem;
  }
}

.p-ItemList-mainCol {
  @include media-breakpoint-up(md) {
    @include make-col(10);
    margin-left: auto;
    margin-right: auto;
  }
}

.p-ItemList-group {
  &:not(:first-child) {
    @include section-spacer("margin-top");
  }
  .p-ItemList.--collapsed:not(.--preventCollapsed) & {
    &:not(:first-child) {
      display: none;
    }
  }
}

.p-ItemList-heading {
  @include text-small();
  @include reveal();
  margin-bottom: calc(4rem - 1em);
  .p-ItemList.--collapsed &,
  .p-ItemList.--preventCollapsed & {
    opacity: 0;
  }
}

.p-ItemList-itemCol {
  display: flex;
  @include content-spacer("margin-top", 1);
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0 !important;
  }
  @include media-breakpoint-up(sm) {
    &:nth-child(3) {
      margin-top: 0 !important;
    }
    &:nth-child(3n+2) .p-ItemList-item {
      @include reveal-delay(1);
    }
    &:nth-child(3n+3) .p-ItemList-item {
      @include reveal-delay(2);
    }
  }
  @include media-breakpoint-up(xl) {
    &:nth-child(4) {
      margin-top: 0 !important;
    }
    &:nth-child(4n+1) .p-ItemList-item {
      @include reveal-delay(0);
    }
    &:nth-child(4n+2) .p-ItemList-item {
      @include reveal-delay(1);
    }
    &:nth-child(4n+3) .p-ItemList-item {
      @include reveal-delay(2);
    }
    &:nth-child(4n+4) .p-ItemList-item {
      @include reveal-delay(3);
    }
  }
  .p-ItemList.--collapsed:not(.--preventCollapsed) & {
    &:nth-child(n+5) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      &:nth-child(n+4) {
        display: none;
      }
    }
    @include media-breakpoint-down(xs) {
      &:nth-child(n+3) {
        display: none;
      }
    }
  }
}

.p-ItemList-item {
  @include reveal();
  flex: 0 0 100%;
  will-change: transform;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &[data-admin-factor] {
    &:after {
      content: attr(data-admin-factor);
      position: absolute;
      bottom: 0;
      right: 0;
      color: red;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      opacity: 0;
      transition: opacity 0.1s 0.3s;
      .p-ItemList.--hover & {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}

.p-ItemList-itemFigure {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  margin-bottom: 1.5rem;
}

.p-ItemList-itemImage {
  @include lazyloaded();
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.p-ItemList-itemLink {
  text-decoration: none !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.4s;
  .p-ItemList.--hover &:not(:hover) {
    opacity: 0.4;
  }
}

.p-ItemList-itemCollection {
  font-weight: 700;
}

.p-ItemList-itemTitle {
  font-weight: 400;
}

.p-ItemList-itemCollection,
.p-ItemList-itemTitle,
.p-ItemList-itemDescription {
  @include text-small();
  margin: 0;
}


.p-ItemList-itemDescription {
  opacity: 0.5;
}

// .p-ItemList-itemDescription {
//   font-weight: 300;
//   @include text-xsmall();
// }

.p-ItemList-itemFinishing {
  @include text-xsmall();
  margin: 0;
  margin-top: 1rem;
  span {
    margin-right: 0.5em;
  }
}

.p-ItemList-toggle {
  @include reveal();
  @include content-spacer("margin-top");
  text-align: center;
  .p-ItemList:not(.--collapsed) &,
  .p-ItemList.--preventCollapsed & {
    display: none;
  }
}
