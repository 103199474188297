.SearchBox {
  @include header-spacer();
  @include section-spacer("padding-bottom");
  input[type="text"] {
    padding-right: 3rem;
  }
  .SiteHeader & {
    opacity: 0;
    transform: translateY(1rem) translateZ(0);
  }
  .show-search .SiteHeader & {
    opacity: 1;
    transform: translateZ(0);
    transition: transform 0.6s cubic-bezier("easeOutQuart"), opacity 0.2s;
    transition-delay: 0.15s;
  }
}

.SearchBox-col {
  margin: auto;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.SearchBox-field {
  position: relative;
}

.SearchBox-button {
  appearance: none;
  border: 0;
  width: 1rem;
  height: 1rem;
  background: none;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0.5;
  .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &[type="reset"] {
    display: none;
    right: 1.5rem;
    .has-search &,
    .not-found & {
      display: block;
    }
  }
  .is-loading & {
    opacity: 1;
    .Icon {
      opacity: 0;
    }
    &:after {
      content: '';
      position: absolute;
      border: 2px solid currentColor;
      border-bottom-color: #fff;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      top: 50%;
      left: 0;
      margin: -0.5rem 0 0;
      transform-origin: 50% 50%;
      opacity: 0.9;
      animation: 0.5s linear infinite fancybox-rotate;
    }
  }
}

.SearchBox-alternative {
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  a {
    @include text-xsmall();
    font-weight: 500;
    display: flex;
    align-items: center;
    color: currentColor;
    letter-spacing: $letter-spacing;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 0.2s;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
      background: url(svg("grid", "fill: #000; stroke: none;")) center/contain no-repeat;
    }
  }
}
