.HpTextImage {
  @include section-spacer(("padding-top","padding-bottom"));
  position: relative;
  @include media-breakpoint-up(sm) {
    padding: 0;
  }
}

.HpTextImage-item {
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-up(sm) {
    min-height: 50vh;
  }
  @include media-breakpoint-up(md) {
    min-height: 80vh;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
  .HpSections.--hasSlides & {
    height: 100vh;
  }
}

.HpTextImage-media {
  position: static;
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    .--reverse & {
      order: 2;
    }
  }
}

.HpTextImage-figure {
  // @include reveal(".HpTextImage-media");
  @include res-prop("margin-bottom", $grid-container-paddings, 2);
  position: relative;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    padding: 0;
    .--reverse & {
      left: 50%;
    }
  }

}

.HpTextImage-placeholder {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.HpTextImage-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: center/contain no-repeat;
}

.HpTextImage-content {
  @include section-spacer(("padding-top","padding-bottom"));
  @include media-breakpoint-down(xs) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(5);
    margin: 0 auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
    margin: 0 auto;
  }
}

.HpTextImage-body {
  @include reveal(".HpTextImage-content");
}

