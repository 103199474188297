.ImageParallax {
  overflow: hidden;
  height: 100vh;
  position: relative;
  @media (orientation: portrait) {
    height: 100vmin;
  }
}

.ImageParallax-container {
  position: absolute;
  height: 100vh;
  top: 0;
  width: 100%;
  @media (orientation: portrait) {
    height: 100vmin;
  }
}

.ImageParallax-figure {
  position: absolute;
  @include res-prop("top", $site-header-heights);
  left: 0;
  right: 0;
  bottom: 3%;
  will-change: transform;
  @media (orientation: portrait) {
    top: 3%;
  }
}

.ImageParallax-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ImageParallax-wrapper {
  position: relative;
  margin: auto;
}

