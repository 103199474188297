.Pagination {
  @include reveal();
  @include make-container();
}

.Pagination-row {
  @include make-row();
  justify-content: center;
}

.Pagination-col {
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(10);

  }
}
