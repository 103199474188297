.Prose {

  p,
  ul {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 3rem;
    }
  }

  a {
    text-decoration: underline;
  }

  > *:first-child {
    margin-top: 0 !important;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  > iframe {
    margin: 1.5rem 0;
  }

  ul {
    margin-left: 0;
    padding-left: 1.2em;
  }

  // anchors
  a[id][name]:not([class]) {
    top: -7rem;
    position: relative;
  }
}
