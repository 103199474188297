.HpSections {
  overflow: hidden;
  > * {
    position: relative;
  }
  .Section {
    margin-top: 0 !important;
  }
}

// .HpSections ~ * {
//   position: relative;
// }

.HpSections + .Section:not(:first-child) {
  margin-top: 0;
}

.HpSections-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}
