.NewsNav {
  @include header-spacer();
  @include content-spacer("margin-bottom", 0.5);
  position: relative;
  z-index: 2;
}

.NewsNav-col {
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    margin: auto;
  }
}

.NewsNav-header {
  @include content-spacer("margin-bottom");
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.NewsNav-title {
  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }
}

.NewsNav-toggle {
  @include text-small();
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.NewsNav-filters {
  @include ms-respond(font-size, 15);
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  @include media-breakpoint-down(xs) {
    font-size: 1.1rem;
  }
  a {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transform: translateX(-1.5rem);
    transition: transform 0.3s;
    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      // background-color: #ccc;
      opacity: 0;
      transition: opacity 0.2s, transform 0.15s;
      transform: scale(0.5);
      background: center/contain no-repeat;
    }
  }
}

.NewsNav-filters a:not(.is-active):before,
.NewsNav-filters a.is-active:hover:before {
  background-image: url(svg("plus", "fill: none; stroke: #fff; stroke-width: 1.3px"));
}

.NewsNav-filters a.is-active:before {
  background-image: url(svg("flag", "fill: none; stroke: #fff; stroke-width: 1.2px"));
}

.NewsNav-filters a.is-active:hover:before {
  transform: scale(1) rotate(-45deg);
}

.NewsNav-filters a:hover,
.NewsNav-filters a.is-active {
  transform: translateX(0);
  &:before {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.2s 0.1s, transform 0.15s;
  }
}
