.BlockUI {
  position: relative;
  pointer-events: none;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(#fff, .4);
    z-index: 2;
  }
}
