.ImageCompare {
  // it's not a section so it needs some basic section styles
  @include fix-sections-flickr();
  background-color: var(--color-background);
  .ba-slider {
    @include reveal();
  }
  .handle {
    width: 1px;
    background: none;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 1px;
      right: auto;
      top: 0;
      bottom: auto;
      height: 50% !important;
      margin: 0 !important;
      width: 1px !important;
      border: 0;
      box-shadow: none;
      transition: none;
      background: linear-gradient(rgba($color-text, 0%) 0%, rgba($color-text, 100%) 15%);
      opacity: 0.3;
    }
    &::after {
      transform-origin: 0 100%;
      transform: rotate(180deg);
    }
  }
  .ba-slider .handle {
    margin-left: -1px;
  }
}

.ImageCompare.color-inverted .handle {
  &::before,
  &::after {
    background: linear-gradient(rgba($color-text-inverted, 0%) 0%, rgba($color-text-inverted, 100%) 15%);
  }
}

.ImageCompare-figure {
  position: relative;
  width: 100vw;
  height: 100vh;
  @media (orientation: portrait) {
    height: 100vmin;
  }
}

.ImageCompare-image {
  @include lazyloaded();
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/contain no-repeat;
}

.ImageCompare-icon {
  background-color: var(--color-text);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(calc(-1rem + 0.5px), -50%);
  transition: 0.2s transform;
  svg {
    stroke: var(--color-text-inverted);
  }
  &:active {
    transform: translate(calc(-1rem + 0.5px), -50%) scale(0.9);
  }
}
