.CookieAlert {
  @include text-xsmall();
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex-cookie-alert;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 1rem;
  display: none;
  transform: translateY(200%);
  transition: transform 0.6s;

  @include media-breakpoint-up(sm) {
    bottom: 2rem;
    right: 2rem;
    left: auto;
    width: 22rem;
    br {
      display: none;
    }
  }

  &.is-ready {
    display: block;
  }

  &.is-visible {
    transition: none;
    transform: translateY(0);
  }
}

.CookieAlert-link {
  text-decoration: underline !important;
}

.CookieAlert-action {
  padding-top: 8px;
  text-align: right;
}
