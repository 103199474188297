.Finishing {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.2em;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-size: 100% 100%;
  transform: translateZ(0);
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 1px solid $color-text;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    transition: opacity 0.2s, transform 0.2s;
  }
  &.--big {
    width: 18px;
    height: 18px;
    @include media-breakpoint-up(sm) {
      width: 22px;
      height: 22px;
    }
    @include media-breakpoint-up(lg) {
      width: 28px;
      height: 28px;
    }
  }
  .is-active & {
    &:before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.Finishing-coating {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: 100% 100%;
}
